import { Vector2 } from "three";
import Node from "../Classes/Node";
import { Convert } from "../Components/PlanEditor/helpers";
import { DiscoveryResultType, PlanarFaceTree } from "./PlanarFaceTree";

export const getDeltas = (plan, link) => {
  let da1 = 0;
  let da2 = 0;
  let db1 = 0;
  let db2 = 0;

  // const links = [...plan.links];
  // if(plan.virtualLink) links.push(plan.virtualLink);
  //
  // const linksA = links.filter(lnk=>{
  //     if(lnk === link) return false;
  //     if(lnk.a === link.a || lnk.b === link.a) return true;
  //     return false;
  // });
  //
  // const linksB = plan.links.filter(lnk=>{
  //     if(lnk === link) return false;
  //     if(lnk.a === link.b || lnk.b === link.b) return true;
  //     return false;
  // });
  // console.log('linksA,linksB',linksA,linksB)
  //
  // if(linksA.length === 1){
  //     const v = link.b.clone().sub(link.a);
  //     const a = v.angle();
  //     const lnk = linksA[0];
  //     const p = lnk.a===link.a?lnk.b:lnk.a;
  //     const va = p.clone().sub(link.a).rotateAround(new Node(0,0),-a).normalize();
  //     if(Math.abs(va.y)>0.00001){
  //         da1 =-(link.h/2*va.x/va.y+lnk.h/2/Math.sin(va.angle()));
  //         da2 = -da1;
  //     }
  // }
  // else if(linksA.length > 1){
  //     let vecUp = null;
  //     let vecDown = null;
  //     let hUp = null;
  //     let hDown = null;
  //     const v = link.b.clone().sub(link.a);
  //     const a = v.angle();
  //     linksA.map(lnk=>{
  //         const p = lnk.a===link.a?lnk.b:lnk.a;
  //         const va = p.clone().sub(link.a).rotateAround(new Node(0,0),-a).normalize();
  //         if(vecUp === null){
  //             vecUp = va;
  //             vecDown = va;
  //             hUp = hDown = lnk.h/2;
  //         }
  //         else{
  //             if(va.angle()<vecUp.angle()){
  //                 vecUp = va;
  //                 hUp = lnk.h/2;
  //             }
  //             else if(va.angle()>vecDown.angle()){
  //                 vecDown = va;
  //                 hDown = lnk.h/2;
  //             }
  //         }
  //     });
  //     if(Math.abs(vecUp.y)>0.00001){
  //         da1 =(hUp*vecUp.x/vecUp.y+hUp/Math.sin(vecUp.angle()));
  //     }
  //     if(Math.abs(vecDown.y)>0.00001){
  //         da2 = -(hDown*vecDown.x/vecDown.y+hDown/Math.sin(vecDown.angle()));
  //     }
  // }
  // if(linksB.length === 1){
  //
  //     const v = link.a.clone().sub(link.b);
  //     const a = v.angle();
  //     const lnk = linksB[0];
  //     const p = lnk.a===link.b?lnk.b:lnk.a;
  //     const vb = p.clone().sub(link.b).rotateAround(new Node(0,0),-a).normalize();
  //     if(Math.abs(vb.y)>0.00001){
  //         db1 =(link.h/2*vb.x/vb.y+lnk.h/2/Math.sin(vb.angle()));
  //         db2 = -db1;
  //     }
  // }
  // else if(linksB.length > 1){
  //     let vecUp = null;
  //     let vecDown = null;
  //     let hUp = null;
  //     let hDown = null;
  //     const v = link.a.clone().sub(link.b);
  //     const a = v.angle();
  //     linksB.map(lnk=>{
  //         const p = lnk.a===link.a?lnk.b:lnk.a;
  //         const va = p.clone().sub(link.a).rotateAround(new Node(0,0),-a).normalize();
  //         if(vecUp === null){
  //             vecUp = va;
  //             vecDown = va;
  //             hUp = hDown = lnk.h/2;
  //         }
  //         else{
  //             if(va.angle()<vecUp.angle()){
  //                 vecUp = va;
  //                 hUp = lnk.h/2;
  //             }
  //             else if(va.angle()>vecDown.angle()){
  //                 vecDown = va;
  //                 hDown = lnk.h/2;
  //             }
  //         }
  //     });
  //     if(Math.abs(vecUp.y)>0.00001){
  //         db1 =-(hUp*vecUp.x/vecUp.y+hUp/Math.sin(vecUp.angle()));
  //     }
  //     if(Math.abs(vecDown.y)>0.00001){
  //         db2 = (hDown*vecDown.x/vecDown.y+hDown/Math.sin(vecDown.angle()));
  //     }
  // }
  if (da1 > 60 || da1 < -60) da1 = 0;
  if (da2 > 60 || da2 < -60) da2 = 0;
  if (db1 > 60 || db1 < -60) db1 = 0;
  if (db2 > 60 || db2 < -60) db2 = 0;
  return [
    { top: da1, middle: 0, bottom: da2 },
    { top: db1, middle: 0, bottom: db2 },
  ];
};
export const OLD__getDeltas = (plan, link) => {
  let da1 = 0;
  let da2 = 0;
  let db1 = 0;
  let db2 = 0;

  const links = [...plan.links];
  if (plan.virtualLink) links.push(plan.virtualLink);

  const linksA = links.filter((lnk) => {
    if (lnk === link) return false;
    if (lnk.a === link.a || lnk.b === link.a) return true;
    return false;
  });

  const linksB = plan.links.filter((lnk) => {
    if (lnk === link) return false;
    if (lnk.a === link.b || lnk.b === link.b) return true;
    return false;
  });

  if (linksA?.length === 1) {
    const v = link.b.clone().sub(link.a);
    const a = v.angle();
    const lnk = linksA[0];
    const p = lnk.a === link.a ? lnk.b : lnk.a;
    const va = p
      .clone()
      .sub(link.a)
      .rotateAround(new Node(0, 0), -a)
      .normalize();
    if (Math.abs(va.y) > 0.00001) {
      da1 = -(((link.h / 2) * va.x) / va.y + lnk.h / 2 / Math.sin(va.angle()));
      da2 = -da1;
    }
  } else if (linksA?.length > 1) {
    let vecUp = null;
    let vecDown = null;
    let hUp = null;
    let hDown = null;
    const v = link.b.clone().sub(link.a);
    const a = v.angle();
    linksA.map((lnk) => {
      const p = lnk.a === link.a ? lnk.b : lnk.a;
      const va = p
        .clone()
        .sub(link.a)
        .rotateAround(new Node(0, 0), -a)
        .normalize();
      if (vecUp === null) {
        vecUp = va;
        vecDown = va;
        hUp = hDown = lnk.h / 2;
      } else {
        if (va.angle() < vecUp.angle()) {
          vecUp = va;
          hUp = lnk.h / 2;
        } else if (va.angle() > vecDown.angle()) {
          vecDown = va;
          hDown = lnk.h / 2;
        }
      }
    });
    if (Math.abs(vecUp.y) > 0.00001) {
      da1 = (hUp * vecUp.x) / vecUp.y + hUp / Math.sin(vecUp.angle());
    }
    if (Math.abs(vecDown.y) > 0.00001) {
      da2 = -(
        (hDown * vecDown.x) / vecDown.y +
        hDown / Math.sin(vecDown.angle())
      );
    }
  }
  if (linksB?.length === 1) {
    const v = link.a.clone().sub(link.b);
    const a = v.angle();
    const lnk = linksB[0];
    const p = lnk.a === link.b ? lnk.b : lnk.a;
    const vb = p
      .clone()
      .sub(link.b)
      .rotateAround(new Node(0, 0), -a)
      .normalize();
    if (Math.abs(vb.y) > 0.00001) {
      db1 = ((link.h / 2) * vb.x) / vb.y + lnk.h / 2 / Math.sin(vb.angle());
      db2 = -db1;
    }
  } else if (linksB?.length > 1) {
    let vecUp = null;
    let vecDown = null;
    let hUp = null;
    let hDown = null;
    const v = link.a.clone().sub(link.b);
    const a = v.angle();
    linksB.map((lnk) => {
      const p = lnk.a === link.a ? lnk.b : lnk.a;
      const va = p
        .clone()
        .sub(link.a)
        .rotateAround(new Node(0, 0), -a)
        .normalize();
      if (vecUp === null) {
        vecUp = va;
        vecDown = va;
        hUp = hDown = lnk.h / 2;
      } else {
        if (va.angle() < vecUp.angle()) {
          vecUp = va;
          hUp = lnk.h / 2;
        } else if (va.angle() > vecDown.angle()) {
          vecDown = va;
          hDown = lnk.h / 2;
        }
      }
    });
    if (Math.abs(vecUp.y) > 0.00001) {
      db1 = -((hUp * vecUp.x) / vecUp.y + hUp / Math.sin(vecUp.angle()));
    }
    if (Math.abs(vecDown.y) > 0.00001) {
      db2 = (hDown * vecDown.x) / vecDown.y + hDown / Math.sin(vecDown.angle());
    }
  }
  if (da1 > 60 || da1 < -60) da1 = 0;
  if (da2 > 60 || da2 < -60) da2 = 0;
  if (db1 > 60 || db1 < -60) db1 = 0;
  if (db2 > 60 || db2 < -60) db2 = 0;
  return [
    { top: da1, middle: 0, bottom: da2 },
    { top: db1, middle: 0, bottom: db2 },
  ];
};

export const formatPrice = (price) => {
  return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + ".-";
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  // return color+'30'
  return color;
};

export const getIntersection = (lineA, lineB) => {
  let A, B, C;
  let pointxx, pointyy;

  if (CrossingCheck(lineA.x, lineA.y, lineB.x, lineB.y)) {
    let a1, b1, c1, a2, b2, c2;
    EquationOfTheLine(lineA.x, lineA.y);
    a1 = A;
    b1 = B;
    c1 = C;
    EquationOfTheLine(lineB.x, lineB.y);
    a2 = A;
    b2 = B;
    c2 = C;
    pointxx = IntersectionX(a1, b1, c1, a2, b2, c2);
    pointyy = IntersectionY(a1, b1, c1, a2, b2, c2);

    return { x: pointxx, y: pointyy };
  } else {
    return false;
  }

  function VEK(ax, ay, bx, by) {
    return ax * by - bx * ay;
  }

  function CrossingCheck(p1, p2, p3, p4) {
    let v1, v2, v3, v4;

    v1 = VEK(p4.x - p3.x, p4.y - p3.y, p1.x - p3.x, p1.y - p3.y);
    v2 = VEK(p4.x - p3.x, p4.y - p3.y, p2.x - p3.x, p2.y - p3.y);
    v3 = VEK(p2.x - p1.x, p2.y - p1.y, p3.x - p1.x, p3.y - p1.y);
    v4 = VEK(p2.x - p1.x, p2.y - p1.y, p4.x - p1.x, p4.y - p1.y);

    return v1 * v2 < -1e-10 && v3 * v4 < -1e-10;
  }

  function EquationOfTheLine(p1, p2) {
    //построение уравнения прямой Ax+By+C
    A = p2.y - p1.y;
    B = p1.x - p2.x;
    C = -p1.x * (p2.y - p1.y) + p1.y * (p2.x - p1.x);
  }

  function IntersectionX(a1, b1, c1, a2, b2, c2) {
    let d, dx, pointx;
    d = a1 * b2 - b1 * a2;
    dx = -c1 * b2 + b1 * c2;
    pointx = dx / d;
    return pointx;
  }

  function IntersectionY(a1, b1, c1, a2, b2, c2) {
    let d, dy, pointy;
    d = a1 * b2 - b1 * a2;
    dy = -a1 * c2 + c1 * a2;
    pointy = dy / d;
    return pointy;
  }
};

export const getIntersectionLongLines = (lineA, lineB) => {
  const vBase1 = lineA.a.clone().sub(lineA.b).clone().setLength(7000);
  const pplA = lineA.a.clone();
  const pplB = lineA.b.clone();
  pplA.x = pplA.x + vBase1.x;
  pplA.y = pplA.y + vBase1.y;
  pplB.x = pplB.x - vBase1.x;
  pplB.y = pplB.y - vBase1.y;

  const vBase2 = lineB.a.clone().sub(lineB.b).clone().setLength(7000);
  const cplA = lineB.a.clone();
  const cplB = lineB.b.clone();
  cplA.x = cplA.x + vBase2.x;
  cplA.y = cplA.y + vBase2.y;
  cplB.x = cplB.x - vBase2.x;
  cplB.y = cplB.y - vBase2.y;

  const crossPoint = getIntersection(
    { x: pplA, y: pplB },
    { x: cplA, y: cplB }
  );

  return crossPoint;
};

export const getSquare = (floor) => {
  let square = 0;
  for (let i = 0; i < floor.length; i++) {
    if (i === floor.length - 1)
      square += floor[i].x * floor[0].y - floor[i].y * floor[0].x;
    else square += floor[i].x * floor[i + 1].y - floor[i].y * floor[i + 1].x;
  }
  square /= 2;
  square = Math.abs(square);
  return square;
};
/**
 * Находит точку пересечения линий
 * @param {[Vector2, Vector2]} line1 массив точек линии
 * @param {[Vector2, Vector2]} line2 массив точек линии
 * @returns Vector2 - точка пересечения
 */
export const lineLineIntersection = (line1, line2) => {
  const a1 = line1[1].y - line1[0].y;
  const b1 = line1[0].x - line1[1].x;
  const c1 = a1 * line1[0].x + b1 * line1[0].y;

  const a2 = line2[1].y - line2[0].y;
  const b2 = line2[0].x - line2[1].x;
  const c2 = a2 * line2[0].x + b2 * line2[0].y;

  const determinant = a1 * b2 - a2 * b1;

  if (determinant === 0) {
    return false;
  } else {
    const x = (b2 * c1 - b1 * c2) / determinant;
    const y = (a1 * c2 - a2 * c1) / determinant;
    return new Vector2(x, y);
  }
};

export const lineLineIntersectionOnNodes = (lineNodes1, lineNodes2) => {
  const line1 = [
    new Vector2(lineNodes1[0].x, lineNodes1[0].y),
    new Vector2(lineNodes1[1].x, lineNodes1[1].y),
  ];
  const line2 = [
    new Vector2(lineNodes2[0].x, lineNodes2[0].y),
    new Vector2(lineNodes2[1].x, lineNodes2[1].y),
  ];
  const result = lineLineIntersection(line1, line2);
  return result ? { x: result.x, y: result.y } : false;
};

/*
export const OLD__getSquare = (plan,floor)=>{
    const squareResult = {loop:[],square:0,loopDef:[],squareDef:0}
    let square = 0;
    let squareDef = false;
    let __floor = [];
    floor.forEach(nodeID => {
        __floor.push(plan.nodes[nodeID])
    });

    const loop = new Cycle()
    let prevNode = floor.length - 1
    for(let i = 0; i < floor.length; i++){
        const node_1 = plan.nodes[floor[prevNode]]
        const node_2 = plan.nodes[floor[i]]
        const linkID = plan.links.findIndex(link => {
            if (
                (link.a === node_1 && link.b === node_2) ||
                (link.a === node_2 && link.b === node_1)
            ) return true
            else return false
        })
        if (undefined !== linkID)
            loop.add(plan.links[linkID])

        prevNode = i
    }
    // console.log('loop',loop)

    const floor1 = []
    const floor2 = []
    for (let i = 0; i < loop._links.length; i++) {
        const wall = loop._links[i]
        const nextWall = (i+1 === loop._links.length)?loop._links[0]:loop._links[i+1]
        if (floor1.length === 0 && floor2.length === 0) {
            if (undefined === wall ||undefined === wall.rA1 || undefined === nextWall || undefined === nextWall.rA1) {
                squareDef = true
                break;
            }
            if (
                (wall.rA1.x === nextWall.rA1.x && wall.rA1.y === nextWall.rA1.y) ||
                (wall.rA1.x === nextWall.rA2.x && wall.rA1.y === nextWall.rA2.y) ||
                (wall.rA1.x === nextWall.rB1.x && wall.rA1.y === nextWall.rB1.y) ||
                (wall.rA1.x === nextWall.rB2.x && wall.rA1.y === nextWall.rB2.y)
            ) {
                floor1.push(wall.rA1)
                floor2.push(wall.rB1)
            } else if (
                (wall.rA2.x === nextWall.rA1.x && wall.rA2.y === nextWall.rA1.y) ||
                (wall.rA2.x === nextWall.rA2.x && wall.rA2.y === nextWall.rA2.y) ||
                (wall.rA2.x === nextWall.rB1.x && wall.rA2.y === nextWall.rB1.y) ||
                (wall.rA2.x === nextWall.rB2.x && wall.rA2.y === nextWall.rB2.y)
            ) {
                floor1.push(wall.rA2)
                floor2.push(wall.rB2)
            } else if (
                (wall.rB1.x === nextWall.rA1.x && wall.rB1.y === nextWall.rA1.y) ||
                (wall.rB1.x === nextWall.rA2.x && wall.rB1.y === nextWall.rA2.y) ||
                (wall.rB1.x === nextWall.rB1.x && wall.rB1.y === nextWall.rB1.y) ||
                (wall.rB1.x === nextWall.rB2.x && wall.rB1.y === nextWall.rB2.y)
            ) {
                floor1.push(wall.rA1)
                floor2.push(wall.rB1)
            } else if (
                (wall.rB2.x === nextWall.rA1.x && wall.rB2.y === nextWall.rA1.y) ||
                (wall.rB2.x === nextWall.rA2.x && wall.rB2.y === nextWall.rA2.y) ||
                (wall.rB2.x === nextWall.rB1.x && wall.rB2.y === nextWall.rB1.y) ||
                (wall.rB2.x === nextWall.rB2.x && wall.rB2.y === nextWall.rB2.y)
            ) {
                floor1.push(wall.rA2)
                floor2.push(wall.rB2)
            }

        } else {
            const prev1 = floor1[i-1]
            const prev2 = floor2[i-1]

            if (!prev1 || !prev2 || undefined === wall.rA1) {
                squareDef = true
                break;
            }

            if (wall.rA1.x === prev1.x && wall.rA1.y === prev1.y) {
                floor1.push(wall.rA2)
                floor2.push(wall.rB2)
            } else if (wall.rA2.x === prev1.x && wall.rA2.y === prev1.y) {
                floor1.push(wall.rA1)
                floor2.push(wall.rB1)
            } else if (wall.rB1.x === prev1.x && wall.rB1.y === prev1.y) {
                floor2.push(wall.rA2)
                floor1.push(wall.rB2)
            } else if (wall.rB2.x === prev1.x && wall.rB2.y === prev1.y) {
                floor2.push(wall.rA1)
                floor1.push(wall.rB1)
            } else if (wall.rA1.x === prev2.x && wall.rA1.y === prev2.y) {
                floor2.push(wall.rA2)
                floor1.push(wall.rB2)
            } else if (wall.rA2.x === prev2.x && wall.rA2.y === prev2.y) {
                floor2.push(wall.rA1)
                floor1.push(wall.rB1)
            } else if (wall.rB1.x === prev2.x && wall.rB1.y === prev2.y) {
                floor1.push(wall.rA2)
                floor2.push(wall.rB2)
            } else if (wall.rB2.x === prev2.x && wall.rB2.y === prev2.y) {
                floor1.push(wall.rA1)
                floor2.push(wall.rB1)
            }
        }
    }

    for (let i = 0; i < __floor.length; i++) {
        if (i === __floor.length - 1)
            square += __floor[i].x*__floor[0].y-__floor[i].y*__floor[0].x
        else
            square += __floor[i].x*__floor[i+1].y-__floor[i].y*__floor[i+1].x
    }
    square /= 2
    square = Math.abs(square)

    squareResult.loopDef = __floor
    squareResult.squareDef = square

    if (!squareDef) {
        let square1 = 0
        for (let i = 0; i < floor1.length; i++) {
            if (i === floor1.length - 1)
                square1 += floor1[i].x*floor1[0].y-floor1[i].y*floor1[0].x
            else
                square1 += floor1[i].x*floor1[i+1].y-floor1[i].y*floor1[i+1].x
        }
        square1 /= 2;
        square1 = Math.abs(square1);

        let square2 = 0
        for (let i = 0; i < floor2.length; i++) {
            if (i === floor2.length - 1)
                square2 += floor2[i].x*floor2[0].y-floor2[i].y*floor2[0].x
            else
                square2 += floor2[i].x*floor2[i+1].y-floor2[i].y*floor2[i+1].x
        }
        square2 /= 2;
        square2 = Math.abs(square2)

        const minFloor = (square1 > square2) ? floor2 : floor1
        for (let i = 0; i < loop._links.length; i++) {
            const wall = loop._links[i]
            const nextWall = (i+1 === loop._links.length)?loop._links[0]:loop._links[i+1]

            let count = 0

            if (
                (minFloor[i].x === wall.rA1.x && minFloor[i].y === wall.rA1.y) ||
                (minFloor[i].x === wall.rA2.x && minFloor[i].y === wall.rA2.y) ||
                (minFloor[i].x === wall.rB1.x && minFloor[i].y === wall.rB1.y) ||
                (minFloor[i].x === wall.rB2.x && minFloor[i].y === wall.rB2.y)
            ) count++

            if (
                (minFloor[i].x === nextWall.rA1.x && minFloor[i].y === nextWall.rA1.y) ||
                (minFloor[i].x === nextWall.rA2.x && minFloor[i].y === nextWall.rA2.y) ||
                (minFloor[i].x === nextWall.rB1.x && minFloor[i].y === nextWall.rB1.y) ||
                (minFloor[i].x === nextWall.rB2.x && minFloor[i].y === nextWall.rB2.y)
            ) count++

            if (count < 2) {
                if (count == 1) {
                    // const problemNode = plan.nodes.findIndex(n => {
                    //     minFloor[i].x === n.x && minFloor[i].y === n.y
                    // });
                    // console.log('problemNode',problemNode)
                }

                return squareResult
            }
        }

        squareResult.loop = (square1 > square2) ? floor2 : floor1
        squareResult.square = (square1 > square2) ? square2 : square1
    }
    return squareResult
}
*/

export const polyPoint = (vertices, px, py) => {
  let collision = false;
  let next = 0;
  for (let current = 0; current < vertices?.length; current++) {
    next = current + 1;
    if (next == vertices.length) next = 0;
    let vc = vertices[current];
    let vn = vertices[next];
    if (
      ((vc.y >= py && vn.y < py) || (vc.y < py && vn.y >= py)) &&
      px < ((vn.x - vc.x) * (py - vc.y)) / (vn.y - vc.y) + vc.x
    ) {
      collision = !collision;
    }
  }
  return collision;
};

export const getMesh = (obj) => {
  let mesh = null;
  obj.traverse((o) => {
    if (mesh === null && o.isMesh) mesh = o;
  });
  return mesh;
};

export const getRoot = (obj) => {
  const getParent = (_obj) => {
    if (_obj.parent) {
      if (
        _obj.parent.userData.TYPE &&
        (_obj.parent.userData.TYPE === "STELAGE" ||
          _obj.parent.userData.TYPE === "MODULE" ||
          _obj.parent.userData.TYPE === "DOP" ||
          _obj.parent.userData.TYPE === "KITCHEN")
      )
        return _obj.parent;
      else return getParent(_obj.parent);
    } else return null;
  };
  if (
    obj.userData.TYPE &&
    (obj.userData.TYPE === "STELAGE" ||
      obj.userData.TYPE === "MODULE" ||
      obj.userData.TYPE === "DOP" ||
      obj.parent.userData.TYPE === "KITCHEN")
  )
    return obj;
  else return getParent(obj);
};

export const fileExists = (file_url) => {
  const http = new XMLHttpRequest();
  http.open("HEAD", file_url, false);
  http.send();
  return http.status != 404;
};

export const is_touch_device = () => {
  try {
    let prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

    let mq = function (query) {
      return window.matchMedia(query).matches;
    };

    if (
      "ontouchstart" in window ||
      (typeof window.DocumentTouch !== "undefined" &&
        document instanceof window.DocumentTouch)
    ) {
      return true;
    }

    return mq(["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(""));
  } catch (e) {
    console.error("(Touch detect failed)", e);
    return false;
  }
};

const transformObjectToArray = (node) => {
  let result = [];
  if (node.cycle.length > 0) {
    result.push(node.cycle);
  }
  for (const child of node.children) {
    result = result.concat(transformObjectToArray(child));
  }
  return result;
};

const transformForestToArray = (forest, points) => {
  let result = [];
  for (const node of forest) {
    result = result.concat(transformObjectToArray(node));
  }
  return result.map((cycle) => {
    const newCycle = [...cycle.map((point) => points[point])];
    newCycle.splice(-1, 1);
    return newCycle;
  });
};

export const findCycles = (links) => {
  const solver = new PlanarFaceTree();

  const nodeMapping = new Map();

  links.forEach((link) => {
    nodeMapping.set(`${link.a.x}_${link.a.y}`, link.a);
    nodeMapping.set(`${link.b.x}_${link.b.y}`, link.b);
  });

  const pointsData = Array.from(nodeMapping, ([_, value]) => value);
  const points = pointsData.map((point) => [
    point.x + 100000,
    point.y + 100000,
  ]);

  const edges = links.map((link) => {
    const pointIndexStart = pointsData.findIndex(
      (point) =>
        point.x === nodeMapping.get(`${link.a.x}_${link.a.y}`).x &&
        point.y === nodeMapping.get(`${link.a.x}_${link.a.y}`).y
    );
    const pointIndexEnd = pointsData.findIndex(
      (point) =>
        point.x === nodeMapping.get(`${link.b.x}_${link.b.y}`).x &&
        point.y === nodeMapping.get(`${link.b.x}_${link.b.y}`).y
    );
    return [pointIndexStart, pointIndexEnd];
  });

  const result = solver.discover(points, edges);
  const cycles = transformForestToArray(
    result.type !== DiscoveryResultType.ERROR ? result.forest : [],
    pointsData
  );

  return { cycles };
};

export const getBGProps = (canvasParams, imageBG) => {
  const { zoom } = canvasParams;
  const center = Convert.toPixel(new Node(0, 0), canvasParams);
  const width = imageBG.width * zoom * (imageBG.scale / 100);
  const height = imageBG.width * zoom * (imageBG.scale / 100);
  const x = center.x - width / 2 + imageBG.posX * zoom;
  const y = center.y - height / 2 + imageBG.posY * zoom;
  return {
    width,
    height,
    x,
    y,
  };
};

export const getBGIntersect = (imageBG, point) => {
  if (
    point.x <= imageBG.x + imageBG.width &&
    point.x >= imageBG.x &&
    point.y <= imageBG.y + imageBG.height &&
    point.y >= imageBG.y
  )
    return true;
  return false;
};
