import {
  calculateMaterialAmount,
  calculateMaterialQuantity,
} from "../Helpers/estimate";

class PolygonWall {
  constructor({ planMaterial, height, isAutoGeneration } = {}) {
    this.showModule = true;
    this.isShow = true; //параметр отвечает за показ стены везде
    this.isPolygonWall = true;
    this.isAutoGeneration = isAutoGeneration ?? false;
    this.nodes = [];
    this.links = [];
    this.includedWalls = new Set(); //массив стене которые включен в этот полигон,
    //пока применяется в только при авто генерации стен
    this.estimate = [];

    this.planMaterial = planMaterial ?? "default";
    this.material = window.materials.wall[0];
    this.materialRGB = {
      rgbColor: false,
      rgb: {
        r: "255",
        g: "255",
        b: "255",
        a: "1",
      },
    };

    this.height = height ?? 2700;
    this.objects = [];

    this.objTitle = "";
    this.objComment = "";
    this.objImages = [];
  }

  setEstimate(estimate) {
    this.estimate = estimate
      .filter(
        (est, index, self) =>
          index === self.findIndex((selfJob) => selfJob.id === est.id)
      )
      .map((estimate) => {
        return {
          id: estimate.id,
          externalId: estimate.externalId,
          group: estimate.group,
          stage: estimate.stage,
          price: estimate.price,
          code: estimate.code,
          name: estimate.name,
          cost: estimate.cost,
          unit: estimate.unit,
          object: estimate.object,
          volume: estimate.volume,
          amount: estimate.amount,
          materials: estimate.materials.map((material) => {
            material.volume = estimate.volume;
            material.quantity = calculateMaterialQuantity(estimate, material);
            material.amount = calculateMaterialAmount(material);
            return { ...material };
          }),
        };
      });
  }
}

export default PolygonWall;
