import Link from "../../Link";
import Node from "../../Node";
import PolygonWall from "../../PolygonWall";

export function addPolygonWallByNodesFunc(nodes) {
  const pWall = new PolygonWall();
  for (const _node of nodes) {
    const node = new Node(_node.x, _node.y, "polygonWall");
    this.putNode(node);
    pWall.nodes.push(node);
  }
  for (let i = 0; i < pWall.nodes.length; i++) {
    const curNode = pWall.nodes[i];
    const nextNode = pWall.nodes[(i + 1) % pWall.nodes.length];

    const link = new Link(curNode, nextNode, "polygonWall");

    pWall.links.push(link);
    this.links.push(link);
  }
  this.polygonWalls.push(pWall);
}
