import { MathUtils, Vector2 } from "three";
import { sendRedrawSimpleEvent } from "../../../Helpers/Events";
import Node from "../../Node";
import { polygonsDo } from "./polygonsDo";

export function autoGeneratePolygonWallsFunc() {
  const superMostImportantPolygon = [];
  const leftWallsInCycles = [];
  this.cycles.forEach((cycle) => {
    const links = cycle.links;
    leftWallsInCycles.push({ walls: [] });
    links.forEach((link) => {
      leftWallsInCycles
        .at(-1)
        .walls.push(this.bWalls.find((wall) => wall.mainLink === link));
    });
  });
  this.cycles.forEach((cycle) => {
    const links = cycle.links;
    const walls = [];
    links.forEach((link) => {
      walls.push(this.bWalls.find((wall) => wall.mainLink === link));
    });

    for (const wall of walls) {
      const mainCyclesArray = leftWallsInCycles.find((sCycle) =>
        sCycle.walls.includes(wall)
      );

      if (!mainCyclesArray || mainCyclesArray.walls.length === 1) {
        continue;
      }
      let oneCyclesWallPolygon = [
        [wall.mainLink.a.x, wall.mainLink.a.y],
        [wall.mainLink.b.x, wall.mainLink.b.y],
        [wall.parallelLink.b.x, wall.parallelLink.b.y],
        [wall.parallelLink.a.x, wall.parallelLink.a.y],
        [wall.mainLink.a.x, wall.mainLink.a.y],
      ];
      const includedWalls = new Set([wall]);
      const bWalls = [...this.bWalls];
      bWalls.sort((a, b) => {
        const aabl = getAngleBetweenLines(
          [wall.mainLink.a, wall.mainLink.b],
          [a.mainLink.a, a.mainLink.b]
        );
        const babl = getAngleBetweenLines(
          [wall.mainLink.a, wall.mainLink.b],
          [b.mainLink.a, b.mainLink.b]
        );
        if (aabl < babl) {
          return -1;
        }
        return 1;
      });
      let pushFlag = false;
      for (const checkWall of bWalls) {
        if (
          checkWall === wall ||
          walls.includes(checkWall) ||
          wall.planMaterial !== checkWall.planMaterial
        ) {
          continue;
        }
        const checkCyclesArray = leftWallsInCycles.find((sCycle) =>
          sCycle.walls.includes(checkWall)
        );
        const aabl = getAngleBetweenLines(
          [wall.mainLink.a, wall.mainLink.b],
          [checkWall.mainLink.a, checkWall.mainLink.b]
        );
        if (aabl > 15 && aabl < 165) {
          continue;
        }

        const wallPolygon = [
          [checkWall.mainLink.a.x, checkWall.mainLink.a.y],
          [checkWall.mainLink.b.x, checkWall.mainLink.b.y],
          [checkWall.parallelLink.b.x, checkWall.parallelLink.b.y],
          [checkWall.parallelLink.a.x, checkWall.parallelLink.a.y],
          [checkWall.mainLink.a.x, checkWall.mainLink.a.y],
        ];
        const intersection = polygonsDo.intersection(
          oneCyclesWallPolygon,
          wallPolygon
        );

        if (intersection.length === 0) {
          continue;
        }
        //
        // drawPolygon(this, oneCyclesWallPolygon);
        // drawPolygon(this, wallPolygon);
        // this.removePolygonWall(this.polygonWalls.at(-1));
        // this.removePolygonWall(this.polygonWalls.at(-1));
        // sendRedrawSimpleEvent(document.querySelector("#plan"));
        //

        const polygon = polygonsDo.getSimplifyPolygon(
          polygonsDo.union(oneCyclesWallPolygon, wallPolygon)
        );

        const mainCyclesArrayWalls = mainCyclesArray?.walls?.filter(
          (wallInArray) => wallInArray !== wall
        );
        const checkCyclesArrayWalls = checkCyclesArray?.walls?.filter(
          (wallInArray) => wallInArray !== checkWall
        );
        if (
          mainCyclesArrayWalls?.length === 0 ||
          checkCyclesArrayWalls?.length === 0
        ) {
          continue;
        }
        //
        // drawPolygon(this, ...polygon);
        // this.removePolygonWall(this.polygonWalls.at(-1));
        // sendRedrawSimpleEvent(document.querySelector("#plan"));
        //
        if (mainCyclesArrayWalls) {
          mainCyclesArray.walls = mainCyclesArrayWalls;
        }
        if (checkCyclesArrayWalls) {
          checkCyclesArray.walls = checkCyclesArrayWalls;
        }
        oneCyclesWallPolygon = polygon;
        //
        // drawPolygon(this, oneCyclesWallPolygon);
        // this.removePolygonWall(this.polygonWalls.at(-1));
        // sendRedrawSimpleEvent(document.querySelector("#plan"));
        //
        pushFlag = true;
        includedWalls.add(checkWall);
      }
      if (pushFlag) {
        superMostImportantPolygon.push({
          polygon: oneCyclesWallPolygon,
          includedWalls,
        });
      }
    }
  });
  let oldLonelyPolygons = superMostImportantPolygon;
  let dodo = true;
  do {
    const { joinPolygon, lonelyPolygons } = getJoinPolygon(
      oldLonelyPolygons,
      this
    );
    //
    // drawPolygon(this, joinPolygon.polygon);
    // this.removePolygonWall(this.polygonWalls.at(-1));
    // sendRedrawSimpleEvent(document.querySelector("#plan"));
    //
    this.addAutoGenerationPolygonWall(joinPolygon);
    if (
      oldLonelyPolygons.length === lonelyPolygons.length ||
      lonelyPolygons.length < 2
    ) {
      dodo = false;
    }
    oldLonelyPolygons = lonelyPolygons;
  } while (dodo);

  oldLonelyPolygons.forEach((_polygon) => {
    this.addAutoGenerationPolygonWall(_polygon);
  });

  this.removingZeroLengthLinks();
  this.removingZeroLengthWalls();
  this.clearEmptyNodes();
}

const getAngleBetweenLines = (line1, line2) => {
  const vectorA = new Vector2().subVectors(line1[0], line1[1]).normalize();
  const vectorB = new Vector2().subVectors(line2[0], line2[1]).normalize();

  const dotProduct = vectorA.dot(vectorB);
  return MathUtils.radToDeg(Math.acos(dotProduct));
};
//
//Функция используется для отладки полигонов, применяет по виду с низу
// drawPolygon(this, oneCyclesWallPolygon);
// this.removePolygonWall(this.polygonWalls.at(-1));
// sendRedrawSimpleEvent(document.querySelector("#plan"));
//
const drawPolygon = (plan, polygonArray) => {
  polygonArray.forEach((lastPolygonsArray) => {
    const addP = [];
    lastPolygonsArray.forEach((node) => {
      const _node = new Node(node[0], node[1], "polygonWall");
      addP.push(_node);
    });
    plan.addPolygonWallByNodes(addP);
    sendRedrawSimpleEvent(document.querySelector("#plan"));
  });
};

const getJoinPolygon = (polygons, plan) => {
  if (!polygons) {
    return;
  }
  const lonelyPolygons = [];
  let joinPolygon = polygons[0].polygon;
  const includedWalls = new Set(polygons[0].includedWalls);
  for (let q = 1; q < polygons.length; q++) {
    const currentPolygon = polygons[q];
    if (
      [...polygons[0].includedWalls][0].planMaterial !==
      [...currentPolygon.includedWalls][0].planMaterial
    ) {
      lonelyPolygons.push(currentPolygon);
      continue;
    }
    //
    // drawPolygon(plan, joinPolygon);
    // drawPolygon(plan, currentPolygon.polygon);
    // plan.removePolygonWall(plan.polygonWalls.at(-1));
    // plan.removePolygonWall(plan.polygonWalls.at(-1));
    // sendRedrawSimpleEvent(document.querySelector("#plan"));
    //
    const intersection = polygonsDo.intersection(
      joinPolygon,
      currentPolygon.polygon
    );
    if (intersection && intersection.length > 0) {
      const tempJoinPolygons = polygonsDo.getSimplifyPolygon(
        polygonsDo.union(joinPolygon, currentPolygon.polygon)
      );
      //
      // drawPolygon(plan, ...tempJoinPolygons);
      // plan.removePolygonWall(plan.polygonWalls.at(-1));
      // sendRedrawSimpleEvent(document.querySelector("#plan"));
      //
      joinPolygon = tempJoinPolygons;
      currentPolygon.includedWalls.forEach((value) => includedWalls.add(value));
    } else {
      lonelyPolygons.push(currentPolygon);
    }
  }
  return {
    joinPolygon: { polygon: joinPolygon, includedWalls },
    lonelyPolygons,
  };
};
