export function calculateDistance(point1, point2) {
  const [x1, y1] = point1;
  const [x2, y2] = point2;
  const dx = x2 - x1;
  const dy = y2 - y1;
  const squaredDistance = dx * dx + dy * dy;
  return Math.sqrt(squaredDistance);
}

export const convertNodeToPolygonPoint = (node) => {
  return [node.x, node.y];
};

export function removeStraightLinePoints(points) {
  if (points.length < 3) return points;

  const cleaned = [points[0]];

  for (let q = 1; q < points.length - 1; q++) {
    const prev = cleaned[cleaned.length - 1];
    const curr = points[q];
    const next = points[q + 1];
    const cross =
      (curr[0] - prev[0]) * (next[1] - curr[1]) -
      (curr[1] - prev[1]) * (next[0] - curr[0]);

    if (Math.abs(cross) > 1e-10) {
      cleaned.push(curr);
    }
  }

  cleaned.push(points[points.length - 1]);
  return cleaned;
}
