import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendAnimationEvent,
  sendRedrawSimpleEvent,
  sendUnselectEvent,
} from "../Helpers/Events";
import { actionsState as projectState } from "../Redux/project";
import { SaveButton } from "./Features/Menu";
import { useShortcuts } from "./Features/Shortcuts";

const Control = () => {
  const dispatch = useDispatch();
  const controlRef = useRef(null);

  const sections = useSelector((store) => store.modules.sections);
  const plan = useSelector((state) => state.project.plan);
  const level = useSelector((state) => state.project.level);
  const planeEdit = useSelector((state) => state.project.planeEdit);
  const showEstimated = useSelector((state) => state.project.showEstimated);
  const editMode = useSelector((state) => state.project.editMode);
  const filters = useSelector((state) => state.project.filters);
  const cameraMode = useSelector((state) => state.project.cameraMode);
  const tool = useSelector((state) => state.project.tool);
  const mode = useSelector((state) => state.project.mode);
  const snap = useSelector((state) => state.project.snap);
  const tokenROLE = useSelector((state) => state.project.saveResult.tokenROLE);
  const ImageBG = useSelector((state) => state.project.ImageBG);

  const enableChangeViewMode = useSelector(
    (state) => state.project.userSetup.enableChangeViewMode
  );
  const enableCreateWalls = useSelector(
    (state) => state.project.userSetup.enableCreateWalls
  );
  const enableAddModules = useSelector(
    (state) => state.project.userSetup.enableAddModules
  );
  const enableSaveProject = useSelector(
    (state) => state.project.userSetup.enableSaveProject
  );
  const enableViewMenu = useSelector(
    (state) => state.project.userSetup.enableViewMenu
  );

  const togglePlanEdit = () => {
    toggleFilters("roof", false);

    let moveEditMode = "view";
    if (!planeEdit && enableCreateWalls) {
      moveEditMode = "move";
    }
    //_setTool(moveEditMode); //Вызов приводит к ошибке, т.к. planeEdit в нем присутсвует, а он вот-вот будет переключен
    dispatch(projectState.setEditMode(false));
    dispatch(projectState.setTool(moveEditMode));

    dispatch(projectState.setModal(""));
    dispatch(projectState.showPlaneEdit(!planeEdit));
  };

  const toggleEditMode = () => {
    dispatch(projectState.setModal(""));
    dispatch(projectState.setEditMode(!editMode));
    if (!editMode) {
      // toggleFilters('floors', true);
      toggleFilters("walls", true);
    }

    if (editMode) {
      sendUnselectEvent(document.querySelector("#plan"));
      sendRedrawSimpleEvent(document.querySelector("#plan"));
    }
  };

  const toggleFilters = (filter, val, manually = false) => {
    plan.setActiveObject(null);

    if (!manually) {
      if (filter === "ruler" && tool === "ruler") return false;
      if (filter === "floors" && tool === "floors") return false;
      // if (filter === 'floors' && tool === 'roof') return false
      if (filter === "walls" && (tool === "walls" || tool === "polygonWall"))
        return false;
      if (filter === "roof" && tool === "roof") return false;
      if (filter === "roof" && tool === "floors") return false;
    }

    filters[filter] = val;
    dispatch(projectState.setFilters(filters));

    if (planeEdit) {
      dispatch(projectState.setModal("r" + Math.random()));
      sendUnselectEvent(document.querySelector("#plan"));
      sendRedrawSimpleEvent(document.querySelector("#plan"));
      // dispatch(projectState.setEditMode(false));
    }
  };

  const togglePlanSnap = () => {
    const val = snap === 1 ? 0 : 1;
    dispatch(projectState.setSnap(val));
  };

  const _setTool = (new_tool) => {
    plan.setActiveObject(null);

    if (tool !== new_tool) {
      dispatch(projectState.setTool(new_tool));

      if (new_tool === "ruler") {
        toggleFilters("ruler", true, true);
      } else if (new_tool === "roof") {
        toggleFilters("roof", true, true);
      } else if (new_tool === "floors") {
        toggleFilters("floors", true, true);
        toggleFilters("roof", false, true);
      } else if (new_tool === "walls" || new_tool === "polygonWalls") {
        toggleFilters("walls", true, true);
      }

      if (new_tool === "move" || new_tool === "view")
        dispatch(projectState.setEditMode(false));
      else dispatch(projectState.setEditMode(true));

      if (planeEdit) {
        dispatch(projectState.setModal(""));
        sendUnselectEvent(document.querySelector("#plan"));
        sendRedrawSimpleEvent(document.querySelector("#plan"));
        if (new_tool === "view") {
          sendAnimationEvent(document.querySelector("#plan"));
        }
      }
    }
  };

  const toggleLevel = () => {
    plan.level = level === "floor" ? "roof" : "floor";
    plan.setFloors("floor");
    dispatch(projectState.setLevel(level === "floor" ? "roof" : "floor"));
    sendRedrawSimpleEvent(document.querySelector("#plan"));
  };

  const [time, setTime] = useState(plan.time);

  const undo = () => {
    plan.undo();
    setTime(Date.now());

    dispatch(projectState.setTool(enableCreateWalls ? "move" : "view"));
    dispatch(projectState.setEditMode(false));

    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector("#plan"));
    sendRedrawSimpleEvent(document.querySelector("#plan"));
  };

  const redo = () => {
    plan.redo();
    setTime(Date.now());

    dispatch(projectState.setTool(enableCreateWalls ? "move" : "view"));
    dispatch(projectState.setEditMode(false));

    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector("#plan"));
    sendRedrawSimpleEvent(document.querySelector("#plan"));
  };

  useEffect(() => {
    console.log("Control => useEffect");
    setTime(plan.time);
  }, [filters, tool, snap, plan]);

  useShortcuts({
    onUndo: undo,
    onRedo: redo,
    deps: [filters, tool, snap, plan],
  });

  if (window.conf_nofunc) {
    return (
      <div className="control">
        <div
          className={
            planeEdit
              ? "control__item_sel"
              : "control__item_sel control__item_sel_active"
          }
          onClick={() => {
            togglePlanEdit();
          }}
        >
          <span>2D</span>
          <span></span>
          <span>3D</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="control">
        <div
          className={
            planeEdit
              ? "control__item_sel"
              : "control__item_sel control__item_sel_active"
          }
          onClick={() => {
            if (enableChangeViewMode) togglePlanEdit();
          }}
        >
          <span>2D</span>
          <span></span>
          <span>3D</span>
        </div>

        {!planeEdit && (
          <div className={planeEdit ? "view-modes" : "view-modes active"}>
            <div className="view-mode-title">Режимы:</div>
            <div
              className={cameraMode === 0 ? "view-mode active" : "view-mode"}
              onClick={() => {
                dispatch(projectState.setCameraMode(0));
              }}
            >
              <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                <path
                  fill="#fff"
                  d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l2.29 2.29c.63.63 1.71.18 1.71-.71V8.91c0-.89-1.08-1.34-1.71-.71L17 10.5z"
                ></path>
              </svg>
              <span>камера</span>
            </div>
            <div
              className={cameraMode === 1 ? "view-mode active" : "view-mode"}
              onClick={() => {
                dispatch(projectState.setCameraMode(1));
              }}
            >
              <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                <path
                  fill="#fff"
                  d="M12 7.5c.97 0 1.75-.78 1.75-1.75S12.97 4 12 4s-1.75.78-1.75 1.75S11.03 7.5 12 7.5zM14 20v-5h1v-4.5c0-1.1-.9-2-2-2h-2c-1.1 0-2 .9-2 2V15h1v5h4z"
                ></path>
              </svg>
              <span>от 1 лица</span>
            </div>
          </div>
        )}

        <div className={"view-modes active"}>
          {enableSaveProject && (
            <>
              <div className="view-mode-title">&nbsp;</div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  undo();
                }}
                className={
                  plan.undoArr.length > 1 ? "view-mode active" : "view-mode"
                }
              >
                <img
                  style={{ height: "24px" }}
                  src={window.confComponentUrl + "assets/icons/undo.svg"}
                  alt=""
                />
                <span>Undo</span>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  redo();
                }}
                className={
                  plan.redoArr.length > 0 ? "view-mode active" : "view-mode"
                }
              >
                <img
                  style={{ height: "24px" }}
                  src={window.confComponentUrl + "assets/icons/redo.svg"}
                  alt=""
                />
                <span>Redo</span>
              </div>
            </>
          )}
          {enableViewMenu && <SaveButton />}
          <div
            onClick={(e) => {
              e.preventDefault();
              dispatch(projectState.setModal("estimateResult"));
            }}
            className="view-mode active"
          >
            <img
              style={{ height: "18px" }}
              src={window.confComponentUrl + "assets/icons/icon-estimate.svg"}
              alt=""
            />
            <span>Смета</span>
          </div>
        </div>

        {planeEdit && (
          <div className={"view-modes active"}>
            <div className="view-mode-title">Режимы:</div>
            <div
              className={mode === "default" ? "view-mode active" : "view-mode"}
              onClick={() => {
                dispatch(projectState.setMode("default"));
                _setTool("move");
              }}
            >
              <img
                src={window.confComponentUrl + "assets/icons/edit-plan.svg"}
                alt=""
              />
              <span>Обычный</span>
            </div>
            <div
              className={mode === "union" ? "view-mode active" : "view-mode"}
              onClick={() => {
                dispatch(projectState.setMode("union"));
                _setTool("move");
              }}
            >
              <img
                src={window.confComponentUrl + "assets/icons/unite-plan.svg"}
                alt=""
              />
              <span>Об. помещ.</span>
            </div>
          </div>
        )}

        <div className={"view-modes active"}>
          <div className="view-mode-title">Инструменты:</div>
          <div
            className={tool === "view" ? "view-mode active" : "view-mode"}
            onClick={() => {
              _setTool("view");
            }}
          >
            <img
              src={window.confComponentUrl + "assets/icons/view-eye.svg"}
              alt=""
            />
            <span>Осмотр</span>
          </div>
          {enableCreateWalls && (
            <div
              className={tool === "move" ? "view-mode active" : "view-mode"}
              onClick={() => {
                _setTool("move");
              }}
            >
              <img
                src={window.confComponentUrl + "assets/icons/view-move.svg"}
                alt=""
              />
              <span>Выбор</span>
            </div>
          )}
          {planeEdit && enableCreateWalls && mode === "default" && (
            <>
              <div
                className={tool === "walls" ? "view-mode active" : "view-mode"}
                onClick={() => {
                  _setTool("walls");
                }}
              >
                <img
                  src={window.confComponentUrl + "assets/icons/view-wall.svg"}
                  alt=""
                />
                <span>Стена</span>
              </div>
              <div
                className={
                  tool === "columns" ? "view-mode active" : "view-mode"
                }
                onClick={() => {
                  _setTool("columns");
                }}
              >
                <img
                  src={
                    window.confComponentUrl + "assets/icons/icon-columns.svg"
                  }
                  alt=""
                />
                <span>Колонна</span>
              </div>
              <div
                className={
                  tool === "figures" ? "view-mode active" : "view-mode"
                }
                onClick={() => {
                  _setTool("figures");
                }}
              >
                <img
                  src={
                    window.confComponentUrl + "assets/icons/icon-pentagon.svg"
                  }
                  alt=""
                />
                <span>Фигуры</span>
              </div>
              <div
                className={tool === "ruler" ? "view-mode active" : "view-mode"}
                onClick={() => {
                  _setTool("ruler");
                }}
              >
                <img
                  style={{ height: "24px" }}
                  src={window.confComponentUrl + "assets/icons/view-ruler.svg"}
                  alt=""
                />
                <span>Линейки</span>
              </div>
              <div
                className={tool === "leader" ? "view-mode active" : "view-mode"}
                onClick={() => {
                  _setTool("leader");
                }}
              >
                <img
                  style={{ height: "24px" }}
                  src={window.confComponentUrl + "assets/icons/leader.svg"}
                  alt=""
                />
                <span>Сноска</span>
              </div>
            </>
          )}
          {mode === "union" && (
            <div
              className={
                tool === "polygonWalls" ? "view-mode active" : "view-mode"
              }
              onClick={() => {
                _setTool("polygonWalls");
              }}
            >
              <img
                src={window.confComponentUrl + "assets/icons/view-wall.svg"}
                alt=""
              />
              <span>Об. стена</span>
            </div>
          )}
          {planeEdit && enableAddModules && (
            <div
              className={tool === "objects" ? "view-mode active" : "view-mode"}
              onClick={() => {
                dispatch(projectState.setModal("modules"));
                dispatch(projectState.setSection(sections[0]));
              }}
            >
              <img
                style={{ height: "24px" }}
                src={window.confComponentUrl + "assets/icons/cube-outline.svg"}
                alt=""
              />
              <span>Объекты</span>
            </div>
          )}
          {planeEdit && enableCreateWalls && (
            <div
              className={snap === 1 ? "view-mode active" : "view-mode"}
              onClick={() => {
                togglePlanSnap();
              }}
            >
              <img
                style={{ height: "24px" }}
                src={window.confComponentUrl + "assets/icons/icon-snap.svg"}
                alt=""
              />
              <span>Привязки</span>
            </div>
          )}
        </div>

        {mode === "union" && (
          <div className={"view-modes active"}>
            <div className="view-mode-title">Объединяющие стены:</div>
            <div
              className={"view-mode"}
              onClick={() => {
                plan.autoGeneratePolygonWalls();
                plan.setActionUndo({ type: "plan" });
                sendUnselectEvent(document.querySelector("#plan"));
                sendRedrawSimpleEvent(document.querySelector("#plan"));
                dispatch(
                  projectState.setAlert("Объединяющие стены сгенерированы")
                );
                console.log();
              }}
            >
              <img
                src={window.confComponentUrl + "assets/icons/unite-plan.svg"}
                alt=""
              />
              <span>Авто</span>
            </div>
          </div>
        )}

        <div className="view-modes active">
          <div className="view-mode-title">Отображать:</div>
          <div
            className={filters.materials ? "view-mode active" : "view-mode"}
            onClick={() => {
              toggleFilters("materials", !filters.materials);
            }}
          >
            <img
              src={window.confComponentUrl + "assets/icons/view-material.svg"}
              alt=""
            />
            <span>Материалы</span>
          </div>
          <div
            className={filters.floors ? "view-mode active" : "view-mode"}
            onClick={() => {
              toggleFilters("floors", !filters.floors);
            }}
          >
            <img
              src={window.confComponentUrl + "assets/icons/view-floor.svg"}
              alt=""
            />
            <span>Полы</span>
          </div>
          <div
            className={filters.walls ? "view-mode active" : "view-mode"}
            onClick={() => {
              toggleFilters("walls", !filters.walls);
            }}
          >
            <img
              src={window.confComponentUrl + "assets/icons/view-wall.svg"}
              alt=""
            />
            <span>Стены</span>
          </div>
          {!planeEdit && (
            <div
              className={filters.transparent ? "view-mode active" : "view-mode"}
              onClick={() => {
                toggleFilters("transparent", !filters.transparent);
              }}
            >
              <img
                src={
                  window.confComponentUrl + "assets/icons/view-transparent.svg"
                }
                alt=""
              />
              <span>Прозрач.</span>
            </div>
          )}
          {!planeEdit && (
            <div
              className={filters.roof ? "view-mode active" : "view-mode"}
              onClick={() => {
                toggleFilters("roof", !filters.roof);
              }}
            >
              <img
                src={window.confComponentUrl + "assets/icons/view-top.svg"}
                alt=""
                width="26"
              />
              <span>Потолок</span>
            </div>
          )}
          <div
            className={filters.furniture ? "view-mode active" : "view-mode"}
            onClick={() => {
              toggleFilters("furniture", !filters.furniture);
            }}
          >
            <img
              src={window.confComponentUrl + "assets/icons/view-meb.svg"}
              alt=""
            />
            <span>Мебель</span>
          </div>
          <div
            className={filters.ruler ? "view-mode active" : "view-mode"}
            onClick={() => {
              toggleFilters("ruler", !filters.ruler);
            }}
          >
            <img
              style={{ height: "24px" }}
              src={window.confComponentUrl + "assets/icons/view-ruler.svg"}
              alt=""
            />
            <span>Размеры</span>
          </div>
          <div
            className={filters.angle ? "view-mode active" : "view-mode"}
            onClick={() => {
              toggleFilters("angle", !filters.angle);
            }}
          >
            <img
              style={{ height: "24px" }}
              src={window.confComponentUrl + "assets/icons/angle-ruler.svg"}
              alt=""
            />
            <span>Углы</span>
          </div>
          {ImageBG.id && (
            <div
              className={filters.imageBG ? "view-mode active" : "view-mode"}
              onClick={() => {
                toggleFilters("imageBG", !filters.imageBG);
              }}
            >
              <img
                src={window.confComponentUrl + "assets/icons/image-bg.svg"}
                alt=""
                width="26"
              />
              <span>Из. плана</span>
            </div>
          )}
        </div>

        {planeEdit && (
          <div className="view-modes active">
            <div className="view-mode-title">Уровень:</div>
            <div
              className={
                level === "floor"
                  ? "control__item_sel"
                  : "control__item_sel control__item_sel_active"
              }
              onClick={() => {
                toggleLevel();
              }}
            >
              <span>Пол</span>
              <span></span>
              <span>Потолок</span>
            </div>
          </div>
        )}

        {/*<Undoredo plan={plan} />*/}
        <div className={"view-modes active"}>
          <div
            className={
              !showEstimated
                ? "control__item_sel"
                : "control__item_sel control__item_sel_active"
            }
            onClick={() => {
              dispatch(projectState.setShowEstimated(!showEstimated));
            }}
          >
            <span></span>
            <span></span>
            <span>Показать неразмеченные объекты</span>
          </div>
        </div>
      </div>
    );
  }
};
export default Control;
