import { LWPolylineFlags, TrueColor } from "@tarikjabiri/dxf";
import { polygonsDo } from "../../../../../Classes/Plan/utils/polygonsDo";
import { getWallColor } from "./getWallColor";
import { drawObject } from "./index";
import { drawHatches, drawPolylinesAsLines } from "./primitives";

export const drawPolygonWalls = (dxf, polygonWalls) => {
  for (const wall of polygonWalls) {
    const shownObjects = [
      ...new Set(wall.objects.filter((obj) => !obj?.isHidden)),
    ];
    const wallColor = getWallColor(wall);
    const trueColorWallColor = TrueColor.fromRGB(
      wallColor.r,
      wallColor.g,
      wallColor.b
    );
    if (shownObjects?.length !== 0) {
      const wallPolygon = convertVector2ArrayToPolygon([
        ...wall.nodes,
        wall.nodes[0],
      ]);
      const polygonsToCut = shownObjects.map((shownObject) =>
        convertVector2ArrayToPolygon([
          ...shownObject.points,
          shownObject.points[0],
        ])
      );
      const diffsPolygon = subtractPolygons(wallPolygon, polygonsToCut);
      const resultVector2 = diffsPolygon.map((diffPolygon) => {
        const simpleDiffPolygon = polygonsDo.getSimplifyPolygon(diffPolygon);
        return simpleDiffPolygon.map((point) => ({ x: point[0], y: point[1] }));
      });
      resultVector2.forEach((points) => {
        drawPolylinesAsLines(dxf, { flags: LWPolylineFlags.None }, points);
        drawHatches(dxf, { trueColor: trueColorWallColor }, points);
      });
      shownObjects.forEach((obj) => {
        drawObject(dxf, obj, wall, false);
      });
    } else {
      drawPolylinesAsLines(dxf, { flags: LWPolylineFlags.None }, wall.nodes);
      drawHatches(dxf, { trueColor: trueColorWallColor }, wall.nodes);
    }
  }
};

const convertVector2ArrayToPolygon = (vectorArray) => {
  return vectorArray.map((vector) => [vector.x, vector.y]);
};

const subtractPolygons = (wallPolygon, polygonsToCut) => {
  const returnPolygons = [wallPolygon];
  polygonsToCut.forEach((polygonToCut) => {
    const expandedInner = polygonsDo.getSizingPolygon(
      polygonToCut,
      polygonToCut
    );
    for (let q = 0; q < returnPolygons.length; q++) {
      const remainingPolygon = returnPolygons[q];
      const diffsPolygon = polygonsDo.diff(remainingPolygon, expandedInner);
      if (diffsPolygon.length) {
        returnPolygons.splice(q, 1);
        returnPolygons.push(...diffsPolygon);
      }
    }
  });
  return returnPolygons;
};
