import * as martinez from "martinez-polygon-clipping";
import { calculateDistance } from "./polygonFunc";

export const polygonsDo = {
  /**
   * Возвращает количество символов после запятой.
   * @param {number} number
   * @returns {number}
   */
  countDecimalPlaces(number) {
    const numStr = number.toString();
    const decimalIndex = numStr.indexOf(".");
    if (decimalIndex !== -1) {
      return numStr.length - decimalIndex - 1;
    }
    return 0;
  },

  /**
   * Возвращает максимальное количество символов после запятой среди массива.
   * @param {[number]} numbers
   * @returns {number}
   */
  findMaxFractionalDecimalPlaces(numbers) {
    let maxFractional = 0;
    let maxDecimalPlaces = 0;
    for (const num of numbers) {
      const fractionalPart = num % 1;
      if (fractionalPart > maxFractional) {
        maxFractional = fractionalPart;
        maxDecimalPlaces = this.countDecimalPlaces(num);
      }
    }
    return maxDecimalPlaces;
  },

  /**
   * Функция увеличивает координаты полигона до указанного знака
   * @param {polygon} polygon
   * @param {number} fractionalDecimal
   * @returns {polygon}
   */
  teachPolygon(polygon, fractionalDecimal) {
    const factor = Math.pow(10, fractionalDecimal);
    return polygon.map((point) =>
      point.map((coordinate) => Math.round(coordinate * factor))
    );
  },

  /**
   * Функция уменьшает координаты полигона до указанного знака после запяток
   * @param {polygon} polygon
   * @param {number} fractionalDecimal
   * @returns {polygon}
   */
  deflatePolygon(polygon, fractionalDecimal) {
    const factor = Math.pow(10, -fractionalDecimal);
    return polygon.map((point) =>
      point.map((coordinate) => Math.round(coordinate) * factor)
    );
  },

  /**
   * Возвращает количество символов после запятой среди полигона минус 1.
   * -1 - потому что все равно в последнем знаке ошибается
   * @param {polygon} polygon
   * @returns {number}
   */
  getUseFractionalDecimal(polygon) {
    const maxFractionalDecimal = this.findMaxFractionalDecimalPlaces(
      polygon.flat(Infinity)
    );
    return maxFractionalDecimal === 0 ? 0 : maxFractionalDecimal - 1;
  },

  /**
   * Функция возвращает массив точек пересечения полигонов, если пересечений нет то пустой массив
   * @param {[[x,y]]} polygon1
   * @param {[[x,y]]} polygon2
   * @returns {[[x,y]]}
   */
  intersection(polygon1, polygon2) {
    const useFractionalDecimal = this.getUseFractionalDecimal([
      ...polygon1,
      ...polygon2,
    ]);
    const teachPolygon1 = this.teachPolygon(polygon1, useFractionalDecimal);
    const teachPolygon2 = this.teachPolygon(polygon2, useFractionalDecimal);
    const intersection = martinez.intersection(
      [[teachPolygon1]],
      [[teachPolygon2]]
    );
    return intersection
      ? intersection.map((polygons) =>
          polygons.map((polygon) =>
            this.deflatePolygon(polygon, useFractionalDecimal)
          )
        )
      : [];
  },

  /**
   * Функция возвращает массив точек обедненных полигонов, если пересечений нет то пустой массив
   * @param {[[x,y]]} polygon1
   * @param {[[x,y]]} polygon2
   * @returns {[[x,y]]}
   */
  union(polygon1, polygon2) {
    const useFractionalDecimal = this.getUseFractionalDecimal([
      ...polygon1,
      ...polygon2,
    ]);
    const teachPolygon1 = this.teachPolygon(polygon1, useFractionalDecimal);
    const teachPolygon2 = this.teachPolygon(polygon2, useFractionalDecimal);
    const union = martinez.union([[teachPolygon1]], [[teachPolygon2]]);
    return union.length === 1
      ? this.deflatePolygon(union[0][0], useFractionalDecimal)
      : [];
  },

  /**
   * Функция возвращает массив полигонов полученные после вычитания полигонов, если пересечений нет то пустой массив
   * @param {[[x,y]]} polygon1
   * @param {[[x,y]]} polygon2
   * @returns {[[[x,y]]]}
   */
  diff(polygon1, polygon2) {
    const useFractionalDecimal = this.getUseFractionalDecimal([
      ...polygon1,
      ...polygon2,
    ]);
    const teachPolygon1 = this.teachPolygon(polygon1, useFractionalDecimal);
    const teachPolygon2 = this.teachPolygon(polygon2, useFractionalDecimal);
    const diff = martinez.diff([[teachPolygon1]], [[teachPolygon2]]);
    if (
      diff.length === 1 &&
      this.arePolygonsSamePoints(teachPolygon1, diff[0][0])
    ) {
      return [];
    }
    return diff.map((polygon) =>
      this.deflatePolygon(polygon[0], useFractionalDecimal)
    );
  },

  /**
   * Функция упрощения полигона.
   * Подробнее - Функция убирает из полигона последовательные точки расстояние между которыми меньше едины и
   * точки получившиеся путем захода грани полигона во внутрь полигона
   * @param {polygon} polygon
   * @returns {polygon}
   */
  getSimplifyPolygon(polygon) {
    const _polygon = polygon.filter((point, index, array) => {
      if (index === 0) {
        return true;
      }
      const prevPoint = array[index - 1];
      const distance = calculateDistance(point, prevPoint);
      return distance > 1;
    });
    const tempPolygon = [_polygon[0]];
    for (let q = 0; q < _polygon.length - 2; q++) {
      const point = _polygon[q];
      const nextPoint = _polygon[q + 2];
      const distance = calculateDistance(point, nextPoint);
      if (distance > 1) {
        tempPolygon.push(_polygon[q + 1]);
        continue;
      }
      q++;
    }
    tempPolygon.push(_polygon[0]);
    return this.removeNearlyCollinearPoints(tempPolygon, 1);
  },

  /**
   * Проверяет пересекаются ли полигоны
   * @param {polygon1} polygon1
   * @param {polygon1} polygon2
   * @returns {boolean}
   */
  arePolygonsJoint(polygon1, polygon2) {
    const intersectionResult = this.intersection(polygon1, polygon2);
    return !!(intersectionResult && intersectionResult.length !== 0);
  },

  /**
   * Сортирует точки полигона
   * @param {polygon} polygon
   * @returns {polygon}
   */
  sortPolygon(polygon) {
    return polygon.slice().sort(([x1, y1], [x2, y2]) => x1 - x2 || y1 - y2);
  },

  /**
   * Сравнивает полигоны по точкам
   * @param {polygon} polygon1
   * @param {polygon} polygon2
   * @returns {boolean}
   */
  arePolygonsSamePoints(polygon1, polygon2) {
    return (
      JSON.stringify(this.sortPolygon(polygon1)) ===
      JSON.stringify(this.sortPolygon(polygon2))
    );
  },

  /**
   * Расширяет узкие стороны четырёх угольника на заданное число
   * @param {polygon} polygon - пять точек, 4 грани
   * @param {number} distance
   * @returns {polygon}
   */
  expandLongEdges(polygon, distance) {
    if (distance === 0) {
      return polygon;
    }
    const newPolygon = [...polygon];
    let edges = [];
    let area = this.getSignedArea(polygon);
    let normalSign = area > 0 ? 1 : -1;

    for (let i = 0; i < polygon.length - 1; i++) {
      let [x1, y1] = polygon[i];
      let [x2, y2] = polygon[i + 1];
      let length = Math.hypot(x2 - x1, y2 - y1);
      edges.push({ index: i, length, x1, y1, x2, y2 });
    }
    edges.sort((a, b) => b.length - a.length);
    let longEdges = edges.slice(0, 2);

    for (let edge of longEdges) {
      let { index, x1, y1, x2, y2 } = edge;
      let dx = normalSign * -(y2 - y1);
      let dy = normalSign * (x2 - x1);
      let length = Math.hypot(dx, dy);
      dx = (dx / length) * distance;
      dy = (dy / length) * distance;
      newPolygon[index] = [x1 + dx, y1 + dy];
      newPolygon[index + 1] = [x2 + dx, y2 + dy];
    }
    newPolygon[newPolygon.length - 1] = newPolygon[0];
    return newPolygon;
  },

  /**
   * Определяем порядок построения полигона.
   * Если площадь > 0 → полигон идёт против часовой (наружу).
   * Если площадь < 0 → полигон идёт по часовой (внутрь, значит нормаль в другую сторону).
   * @param {polygon} polygon
   * @returns {number}
   */
  getSignedArea(polygon) {
    let sum = 0;
    for (let i = 0; i < polygon.length - 1; i++) {
      let [x1, y1] = polygon[i];
      let [x2, y2] = polygon[i + 1];
      sum += (x2 - x1) * (y2 + y1);
    }
    return sum / 2;
  },

  /**
   * Подбирает размеры для внутреннего полигона (polygonDown),
   *  что бы он начал пересекать внешний (polygonUp) и возвращает новый полигон
   * @param {polygon} polygonUp - внешний полигона;
   * @param {polygon} polygonDown - внутреннего полигона;
   * @returns {polygon}
   */
  getSizingPolygon(polygonUp, polygonDown) {
    for (let q = 0; q < 500; q++) {
      const expandedInner = polygonsDo.expandLongEdges(polygonDown, 0.01 * q);
      const intersectionPolygon = this.intersection(polygonUp, expandedInner);
      if (
        intersectionPolygon &&
        intersectionPolygon[0] &&
        intersectionPolygon[0][0].length > 4
      ) {
        return expandedInner;
      }
    }
    return polygonDown;
  },
  /**
   *Функция возвращает полигон без линий "туда-обратно".
   * @param {polygon} polygon
   * @param {number} angleThreshold - угол пи котором линии считаются "туда-обратно"
   * @returns {polygon} без линий "туда-обратно"
   */
  removeNearlyCollinearPoints(polygon, angleThreshold = 5) {
    if (polygon.length < 4) {
      return polygon;
    }
    let cleanedPolygon = [...polygon];
    let i = 0;
    while (i < cleanedPolygon.length) {
      let prev =
        cleanedPolygon[(i - 1 + cleanedPolygon.length) % cleanedPolygon.length];
      let curr = cleanedPolygon[i];
      let next = cleanedPolygon[(i + 1) % cleanedPolygon.length];
      let vecA = [curr[0] - prev[0], curr[1] - prev[1]];
      let vecB = [next[0] - curr[0], next[1] - curr[1]];
      let angle = angleBetweenVectors(vecA, vecB);

      if (Math.abs(angle - 180) < angleThreshold) {
        cleanedPolygon.splice(i, 1);
        if (i === 0) {
          cleanedPolygon[cleanedPolygon.length - 1] = [...cleanedPolygon[0]];
        }
        i = Math.max(0, i - 1);
      } else {
        i++;
      }
    }
    let first = cleanedPolygon[0];
    let second = cleanedPolygon[1];
    let last = cleanedPolygon[cleanedPolygon.length - 2];
    let firstVec = [second[0] - first[0], second[1] - first[1]];
    let lastVec = [first[0] - last[0], first[1] - last[1]];
    let firstAngle = angleBetweenVectors(lastVec, firstVec);

    if (Math.abs(firstAngle - 180) < angleThreshold) {
      cleanedPolygon.splice(0, 1);
      cleanedPolygon[cleanedPolygon.length - 1] = [...cleanedPolygon[0]];
    }
    return cleanedPolygon;
  },
};
/**
 * Возвращает угол между векторами
 * @param {[,]} a
 * @param {[,]} b
 * @returns {number} в градусах
 */
function angleBetweenVectors(a, b) {
  let dot = a[0] * b[0] + a[1] * b[1];
  let magA = Math.hypot(a[0], a[1]);
  let magB = Math.hypot(b[0], b[1]);

  if (magA === 0 || magB === 0) return 0;

  let cosTheta = Math.max(-1, Math.min(1, dot / (magA * magB)));
  return Math.acos(cosTheta) * (180 / Math.PI);
}
