import { calculateDistance, convertNodeToPolygonPoint } from "./polygonFunc";

export function addAutoGenerationPolygonWallFunc(mainPolygonInfo) {
  snapPolygonToClosestPoints(mainPolygonInfo);
  this.addPolygonWallByNodes(getPolygonPoints(mainPolygonInfo.polygon));
  const polygonWall = this.polygonWalls.at(-1);
  polygonWall.height = [...mainPolygonInfo.includedWalls].sort(
    (a, b) => b.height - a.height
  )[0].mainLink.height;
  polygonWall.planMaterial = getMostFrequentVariant(
    mainPolygonInfo.includedWalls,
    "planMaterial"
  );
  polygonWall.isAutoGeneration = true;
  polygonWall.includedWalls = mainPolygonInfo.includedWalls;
  for (let wall of polygonWall.includedWalls) {
    wall.isShow = false;
    wall.ignoreAutoDisclosure = true;
  }
}

const getPolygonPoints = (polygon) => {
  return polygon.map((point) => ({ x: point[0], y: point[1] }));
};

/**
 * Функция возвращает самый популярное значения свойство среди переданного массива объекта
 * @param {*} walls
 * @param {*} propertyName
 * @returns {string|number}
 */
const getMostFrequentVariant = (walls, propertyName) => {
  const variant = {};
  let returnValue = { nameKey: undefined, points: 0 };
  for (const wall of walls) {
    if (!variant[wall[propertyName]]) {
      variant[wall[propertyName]] = 0;
    }
    variant[wall[propertyName]]++;
  }
  for (const key in variant) {
    if (variant[key] > returnValue.points) {
      returnValue.nameKey = key;
      returnValue.points = variant[key];
    }
  }
  return returnValue.nameKey;
};

const snapPolygonToClosestPoints = (mainPolygonInfo) => {
  for (const wall of mainPolygonInfo.includedWalls) {
    const wallPoints = [
      convertNodeToPolygonPoint(wall.mainLink.a),
      convertNodeToPolygonPoint(wall.mainLink.b),
      convertNodeToPolygonPoint(wall.parallelLink.a),
      convertNodeToPolygonPoint(wall.parallelLink.b),
      convertNodeToPolygonPoint(wall.parallelLink.a),
    ];
    for (let q = 0; q < mainPolygonInfo.polygon.length; q++) {
      let polygonPoint = mainPolygonInfo.polygon[q];
      for (const wallPoint of wallPoints) {
        const distance = calculateDistance(polygonPoint, wallPoint);
        if (distance < 1) {
          mainPolygonInfo.polygon[q] = wallPoint;
          break;
        }
      }
    }
  }
};
